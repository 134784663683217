@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700;900&display=swap');

/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(184, 77%, 34%);
  /* primary/main color */
  --clr-primary-5: #a2d2ff;
  --clr-primary-5-dark: #0069cc;
  --clr-primary-5-active: #80c1ff;
  --clr-primary-5-active-darker: #66b5ff;
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --primary-500: #49a6e9;
  --primary-800: #104e7a;
  --primary-900: #164e63;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #dedede;
  --grey-400: #3d3b48;
  --grey-500: #64748b;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --clr-white: #fff;
  --clr-black: #28283d;
  --clr-red-dark: #842029;
  --clr-green-dark: #0f5132;
  --clr-grey: #87879d;
  --clr-lighter-grey: #e3e3e8;
  --clr-light-grey: #c7c7d1;
  --clr-grey-muted: #bab7d4;
  --clr-off-white: #f2f2f2;
  --clr-off-white-text: #fafafa;
  --clr-pale-blue-bg: rgb(239, 245, 255);
  --clr-bg-grey: #d8d8d8;
  --clr-secondary: #ffc8dd;
  --clr-secondary-active: #ffb3d0;
  --clr-secondary-active-darker: #ff99c0;
  /* font-weight */
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-900: 900;
  --ff-primary: 'Red Hat Display', sans-serif;
  --transition: all 0.3s ease-in-out;
  --spacing: 0.25rem;
  --spacing-slightly-reduced: 0.185rem;
  --spacing-reduced: 0.0625rem;
  --radius-increased: 0.625rem;
  --radius: 0.5rem;
  --radius-input: 0.3125rem;
  --radius-reduced: 0.25rem;
  --width-fixed-540: 540px;
  /* box shadow */
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.15);
  --shadow-button-inset: 0px -3px 0px 0px rgba(0, 0, 0, 0.09) inset;
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/*
=============== 
Global Styles
===============
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: var(--ff-primary);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--ff-primary);
  background: var(--clr-off-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--clr-primary-5-active);
}

img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 2rem;
  line-height: 1.125;
  letter-spacing: unset;
  margin-bottom: 1.5rem;
  font-weight: var(--fw-900);
}

h2 {
  font-size: 2rem;
  color: var(--clr-black);
  line-height: 1.125;
  font-weight: var(--fw-900);
  text-align: center;
}

h3 {
  font-size: 1.25rem;
  color: var(--clr-primary-5-active);
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.625;
  text-align: center;
  font-weight: var(--fw-900);
}

h4 {
  font-size: 0.875rem;
}

h5 {
  font-size: 1rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h4 {
    line-height: 1;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: clamp(40px, 4vw, 62px);
    margin-bottom: 2.5vw;
    line-height: 1.1;
  }

  h2 {
    font-size: clamp(28px, 2.75vw, 46px);
  }

  h3 {
    font-size: clamp(12px, 1.11111vw, 18px);
  }

  h5 {
    font-size: clamp(13px, 1.175vw, 1.15rem);
  }
}

@media screen and (min-width: 1440px) {
  h1 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 320px) {
  h1 {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }
}

/*  global classes */

.btn {
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  display: inline-block;
  /* font-weight: 700; */
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  /* box-shadow: var(--shadow-button-inset); */
}

/* section */
.section {
  background: var(--clr-off-white);
  padding: 5rem 0;
  scroll-margin-top: 3.75rem;
}

.section-title {
  text-align: center;
  margin-bottom: 1.5rem;
  max-width: 72vw;
  margin-left: auto;
  margin-right: auto;
}

.section-title h2 {
  color: var(--clr-off-white-text);
  font-weight: var(--fw-900);
}

.section-title span {
  color: var(--clr-primary-5);
}

.section-center {
  width: 87.2vw;
  margin: 0 auto;
  max-width: 1133.6px;
}

@media screen and (min-width: 768px) {
  .section-center {
    width: 89.75vw;
    max-width: 69.375rem;
  }

  .section-title h2 {
    line-height: 1.1;
    max-width: 34rem;
    margin-left: auto;
    margin-right: auto;
  }

  .section-title.section-title__services h2 {
    max-width: 30rem;
  }
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 77.0833333333vw;
  }
}

/*
=============== 
Custom File Input styling
===============
*/

/* input[type="file" i]::-webkit-file-upload-button {
  display: inline-flex;
  width: 86px;
  justify-content: center;
  margin-left: calc(50% - 94px);
  margin-right: 8px;
}

@media screen and (min-width: 1280px) {
  input[type="file" i]::-webkit-file-upload-button {
    width: 100px;
    margin-left: calc(50% - 108px);
  }
}

@media screen and (min-width: 1440px) {
  input[type="file" i]::-webkit-file-upload-button {
    width: 116px;
    margin-left: calc(50% - 124px);
  }
} */

/*
=============== 
Hero
===============
*/

.hero {
  min-height: 100vh;
  background: var(--clr-off-white);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./images/perfect-match-logo_original_compressed.png);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position-y: 62px;
  background-position-x: center;
  padding-bottom: 16rem;
}

.hero-line-break-tablet {
  display: none;
}

.hero-banner {
  margin-top: 33vh;
  text-align: center;
  color: var(--clr-black);
  padding: 0 1.5rem;
}

.hero-banner p {
  max-width: 28.5625rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.875rem;
  line-height: 1.625;
  font-weight: 500;
  font-size: 1rem;
  color: var(--clr-grey);
}

.hero-banner p:nth-of-type(2),
.hero-banner p:nth-of-type(3) {
  display: none;
}

.hero-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-btn {
  font-family: var(--ff-primary);
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  font-size: 1rem;
  font-weight: 900;
  background: var(--clr-primary-5);
  color: var(--clr-white);
  border-radius: 29px;
  width: 193px;
  line-height: 1.5;
}

.hero-btn:disabled {
  opacity: 0.35;
  pointer-events: none;
}

.hero-btn.hero-btn__secondary {
  margin-top: 1rem;
  background: var(--clr-secondary);
  width: 164px;
}

.hero-btn.hero-btn__contact-form {
  width: 100%;
  border-radius: var(--radius-input);
}

.hero-btn.next-btn,
.hero-btn.back-btn {
  font-size: 0.775rem;
}

.hero-btn.next-btn {
  background: var(--clr-primary-5-active);
  width: calc(50% - .0625rem);
}

.hero-btn.next-btn.btn-secondary,
.hero-btn.back-btn {
  background: unset;
  font-weight: var(--fw-700);
}

.hero-btn.back-btn {
  width: calc(50% - .0625rem);
  box-shadow: unset;
  color: var(--clr-secondary-active);
}

.hero-btn.back-btn.btn-secondary {
  color: var(--clr-grey-muted);
}

.hero-btn.next-btn.btn-secondary {
  color: var(--clr-primary-5-active);
  border: .15rem solid var(--clr-primary-5-active);
}

.hero-btn.next-btn.btn-secondary.btn-confirm {
  width: 10.5rem;
  font-size: .85rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.form .container__btn {
  margin-top: 0;
}

.form .container__btn.move-btn-up {
  margin-top: -8rem;
}

.form .container__btn.move-btn-down {
  margin-top: 1vh;
}

.container__btn-read-agreement {
  margin-top: 1vh;
}

.hero-btn.next-btn.btn-secondary.btn-confirm.btn-read-agreement {
  width: 184px;
}

@media screen and (min-width: 768px) {
  .hero {
    /* background: linear-gradient(rgb(44, 174, 186, 0.7), rgba(0, 0, 0, 0.7)),
      url('./images/main.jpeg') center/cover no-repeat; */
    /* background-image: url('./images/image-hero__tablet__alt.png'); */
    background-size: 66%;
    background-position-y: calc(8.5rem + 5vh);
    min-height: 70vh;
    padding-bottom: 0;
    position: relative;
    z-index: 2;
  }

  .hero-line-break-mobile {
    display: none;
  }

  .hero-line-break-tablet {
    display: inline;
  }

  .hero-banner {
    padding: 0;
    margin-top: 40vh;
  }

  .hero-banner p {
    max-width: 36rem;
    margin-bottom: 1rem;
  }

  .hero-banner p:nth-of-type(2),
  .hero-banner p:nth-of-type(3) {
    display: block;
  }

  .hero-banner p:nth-of-type(3) {
    margin-bottom: 3.5rem;
  }

  .hero-btn-container {
    flex-direction: unset;
    justify-content: center;
    grid-column-gap: 1rem;
  }

  .hero-btn.hero-btn__secondary {
    margin-top: unset;
    width: 180px;
  }

  .hero-btn {
    padding-top: 0.95rem;
    padding-bottom: 0.95rem;
  }

  .hero-btn.next-btn,
  .hero-btn.back-btn {
    font-size: 0.875rem;
  }

  .hero-btn.next-btn.submit-btn {
    font-size: 1rem;
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm {
    width: 12rem;
    font-size: 15px;
    padding-top: .925rem;
    padding-bottom: .925rem;
  }

  .container__btn-read-agreement {
    margin-top: -1vh;
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm.btn-read-agreement {
    width: 204px;
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm.btn-read-agreement.btn-proceed-to-payment {
    width: 216px;
  }

  .form .container__btn.move-btn-up {
    margin-top: -20vh;
  }
}

@media screen and (min-width: 992px) {
  .hero {
    min-height: calc(100vh - 3.75rem);
    /* background-image: url(./images//image-hero-left__alt.png), url(./images/image-hero-right__alt.png); */
    /* background-position: 0 33%, 100% 40%; */
    background-size: clamp(350px, 33vw, 620px);
    background-position-y: 12vh;
    padding-bottom: unset;
    align-items: flex-start;
  }

  .hero-banner {
    margin-top: 23vw;
  }

  .hero-banner p {
    max-width: clamp(496px, 50vw, 800px);
    font-size: clamp(14px, 1.25vw, 20px);
    line-height: 1.44;
  }

  .hero-btn-container {
    grid-column-gap: 1.1111111vw;
  }

  .hero-btn {
    padding-top: clamp(11px, 1.05vw, 18px);
    padding-bottom: clamp(11px, 1.05vw, 18px);
    font-size: clamp(12px, 1.11111vw, 18px);
    font-weight: 900;
    background: var(--clr-primary-5);
    color: var(--clr-white);
    border-radius: clamp(28px, 2vw, 38px);
    width: 14vw;
    min-width: 150px;
    max-width: 252px;
    max-height: 66px;
  }

  .hero-btn.hero-btn__secondary {
    width: clamp(150px, 14vw, 248px);
  }

  .hero-btn:hover {
    background: var(--clr-primary-5-active);
  }

  .hero-btn.hero-btn__secondary:hover {
    background: var(--clr-secondary-active);
  }

  .hero-banner p:nth-of-type(3) {
    margin-bottom: 1.5rem;
  }

  .hero-btn.next-btn,
  .hero-btn.back-btn {
    font-size: clamp(11px, 1.11111vw, 18px);
    width: calc(50% - .25rem);
  }

  .hero-btn.next-btn:hover,
  .hero-btn.next-btn:focus {
    background-color: var(--clr-primary-5-active-darker);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }

  .hero-btn.next-btn.next-btn__child:hover,
  .hero-btn.next-btn.next-btn__child:focus {
    background-color: var(--clr-white);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }

  .hero-btn.back-btn:hover,
  .hero-btn.back-btn:focus {
    background: unset;
    border: 2px solid var(--clr-secondary-active);
  }

  .hero-btn.back-btn.back-btn__child:hover,
  .hero-btn.back-btn.back-btn__child:focus {
    border-color: var(--clr-grey-muted);
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm {
    font-size: clamp(12px, 1.042vw, 17px);
    padding-top: clamp(11px, 1.05vw, 18px);
    padding-bottom: clamp(11px, 1.05vw, 18px);
    width: 14vw;
    max-width: 220px;
    max-height: 66px;
  }

  .hero-btn.next-btn.submit-btn {
    max-width: unset;
    font-size: clamp(14px, 1.125vw, 20px);
    padding-top: 1.333vh;
    padding-bottom: 1.333vh;
    max-height: 60px;
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm.btn-read-agreement {
    width: 15.5vw;
    max-width: 255px;
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm.btn-read-agreement.btn-proceed-to-payment {
    width: 16.5vw;
    position: relative;
    top: -7vh;
    max-width: 270px;
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm:hover,
  .hero-btn.next-btn.btn-secondary.btn-confirm:focus {
    background-color: var(--clr-white);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }

  .container__btn-read-agreement {
    margin-top: 1vh;
  }

  .form .container__btn.move-btn-up {
    margin-top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .hero-btn,
  .hero-btn.hero-btn__secondary {
    width: 15vw;
  }

  .hero-banner {
    margin-top: 21.5vw;
  }

  .hero-banner p:nth-of-type(3) {
    margin-bottom: 2.5rem;
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm.btn-read-agreement.btn-proceed-to-payment {
    top: -10vh;
  }
}

@media screen and (min-width: 1440px) {
  .hero {
    background-position-y: 10vh;
    background-size: clamp(490px, 35vw, 540px);
    min-height: 100vh;
  }

  .hero-banner {
    margin-top: clamp(310px, 20vw, 330px);
  }

  .hero-btn.next-btn.btn-secondary.btn-confirm.btn-read-agreement.btn-proceed-to-payment {
    top: -12vh;
  }
}

@media screen and (max-width: 320px) {
  .hero {
    background-size: 80%;
    background-position-y: 56px;
  }

  .hero-banner {
    margin-top: 35vh;
  }

  .hero-banner p {
    font-size: .825rem;
    margin-bottom: 1.125rem;
  }

  .hero-btn {
    font-size: .825rem;
    width: 163px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .hero-btn.hero-btn__secondary {
    width: 139px;
    margin-top: 0.65rem;
  }
}

/*
=============== 
About
===============
*/
/* section added to globals */
/* title added to globals */
/* section center added to globals */

.section__about {
  padding-top: 0.75rem;
  padding-bottom: 9rem;
}

/* .about-img-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 1.5625rem;
  grid-row-gap: 1.5rem;
  margin-bottom: 4rem;
}

.about-img, .about-photo {
  border-radius: var(--radius);
}

.about-img {
  background: var(--clr-bg-grey);
} */

.about-info {
  margin-bottom: 2rem;
}

.about-info h3 {
  margin-bottom: 1.5rem;
}

.about-info h2 {
  margin-bottom: 2rem;
}

.about-info p {
  line-height: 1.625;
  font-weight: 500;
  font-size: 1rem;
  color: var(--clr-grey);
  text-align: center;
}

@media screen and (min-width: 768px) {
  #about {
    position: relative;
    top: -6vh;
  }

  .section__about {
    padding-top: 22vh;
    padding-bottom: 10rem;
    margin-bottom: -10vh;
  }

  /* .about-img-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0.6875rem;
    margin-bottom: 3rem;
  } */

  .about-info {
    width: 82vw;
    max-width: 69.375rem;
    margin-left: auto;
    margin-right: auto;
  }

  .about-info h2 {
    max-width: 34rem;
    margin: 0 auto 2rem;
    line-height: 1.1;
  }

  .about-info h3 {
    margin-bottom: 1rem;
  }

  .about-info p {
    max-width: 39rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .about-info.about-info__faqs {
    max-width: unset;
    width: unset;
  }

  .about-info.about-info__faqs p {
    max-width: unset;
  }
}

@media screen and (min-width: 992px) {
  #about {
    top: -9vh;
  }

  .section__about {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(90vh - 7.5rem);
    padding-bottom: calc(6vw + .75vh);
    padding-top: 15vw;
    margin-bottom: -2.5vh;
  }

  /* .about-img, */
  .about-info {
    margin-bottom: 0;
    width: unset;
  }

  /* .about-img-container {
    grid-column-gap: 2.08333333vw;
    margin-bottom: 5.555555555556vw;
  } */

  .about-info h2 {
    max-width: 45vw;
    margin: 0 auto 2.22222222vw;
    margin-bottom: 2.5vw;
  }

  .about-info h3 {
    margin-bottom: 1.111111111vw;
  }

  .about-info p {
    font-size: clamp(14px, 1.25vw, 20px);
    line-height: 1.44;
    max-width: 49vw;
    margin-bottom: 1.1111vw;
  }
}

@media screen and (min-width: 1080px) {
  /* .about-img {
    position: relative;
  } */

  .about-photo {
    position: relative;
  }
}

@media screen and (min-width: 1280px) {
  .section__about {
    min-height: calc(77vh - 7.5rem);
  }
}

@media screen and (min-width: 1440px) {
  .about-info h2 {
    margin-bottom: 2.25vw;
  }

  .about-info p:last-child {
    margin-bottom: 0;
  }

  #about {
    top: -16vh;
    padding-bottom: 1vw;
  }
}

@media screen and (min-width: 1920px) {
  .about-info h2 {
    margin-bottom: 2vw;
  }

  .section__about {
    min-height: calc(78.25vh - 7.5rem);
  }

  #about {
    top: -20.5vh;
    padding-bottom: 0;
  }
}

/*
=============== 
Services
===============
*/
.section__services {
  padding-bottom: 7.25rem;
  padding-top: 4.5rem;
  background-color: var(--clr-primary-5);
  position: relative;
  margin-bottom: 3.25rem;
}

.section__services .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  background: url(./images/image-footer.jpg);
  background-size: cover;
  background-color: var(--clr-grey-7);
  background-repeat: no-repeat;
  background-blend-mode: darken;
}

.service {
  text-align: center;
  margin-bottom: 3rem;
}

.service-icon {
  background: var(--clr-primary-5);
  color: var(--clr-primary-1);
  padding: 0.5rem;
  display: inline-block;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.service-info .hero-btn.hero-btn__secondary {
  margin-top: .75rem;
  padding-left: 2.4375rem;
  padding-right: 2.4375rem;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translateX(-50%);
  width: 12.0625rem;
  background: var(--clr-secondary-active);
}

.service-text {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  color: var(--clr-off-white);
  line-height: 1.44;
  font-weight: var(--fw-500);
}

@media screen and (min-width: 576px) {
  /* .services-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  } */

  .service {
    margin-bottom: 0rem;
  }
}

@media screen and (min-width: 768px) {
  .section__services {
    padding-bottom: 9.6875rem;
  }

  .service-text {
    max-width: 38.5rem;
  }

  .section__services .overlay {
    background-image: url(./images/image-footer__tablet.jpg);
  }

  .service-info .hero-btn.hero-btn__secondary {
    bottom: -60%;
  }
}

@media screen and (min-width: 992px) {
  .services-center {
    width: unset;
    display: flex;
    align-items: center;
  }

  .section__services {
    display: grid;
    grid-template-columns: 22.3vw 35.5vw;
    grid-column-gap: 1.5vw;
    padding-top: 3.055555555vw;
    padding-bottom: 3.05555555vw;
    padding-left: 11.458vw;
    min-height: 22.222222222vw;
    margin-bottom: 9vw;
  }

  .section__services .overlay {
    background-image: url(./images/image-footer__desktop.jpg);
  }

  .service {
    display: flex;
    text-align: left;
  }

  .section-title {
    margin-bottom: unset;
    display: flex;
    align-items: center;
  }

  .section-title h2 {
    text-align: left;
  }

  .service-text {
    max-width: unset;
    font-size: clamp(14px, 1.25vw, 20px);
    margin-bottom: 1.3888888889vw;
  }

  .service-text:nth-child(3) {
    margin-bottom: unset;
  }

  .service-info .hero-btn.hero-btn__secondary {
    left: unset;
    bottom: unset;
    top: calc(50% - 2.2vw);
    right: -18vw;
    transform: unset;
    margin-top: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2.7vw;
    padding-right: 2.7vw;
    width: 13.4vw;
  }

  .service-info .hero-btn.hero-btn__secondary:hover {
    background: var(--clr-secondary-active-darker);
  }
}

@media screen and (min-width: 1280px) {
  .service-info .hero-btn.hero-btn__secondary {
    width: 14vw;
    right: -16vw;
  }
}

/*
=============== 
FAQs
===============
*/

.section__about.section__faqs {
  padding-bottom: 8rem;
  padding-top: 0.75rem;
}

.questions-wrapper {
  display: flex;
  flex-direction: column;
}

.question {
  border-radius: var(--radius);
  box-shadow: var(--shadow-3);
  margin-bottom: 1.25rem;
  background-color: var(--clr-off-white-text);
}

.question:nth-last-child(2) {
  order: 3;
}

.question:last-child {
  order: 4;
}

.question header {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background: var(--clr-secondary-active);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.question header.not-expanded {
  border-radius: var(--radius);
}

.question header h5 {
  font-weight: 500;
  line-height: 1.5;
  color: var(--clr-off-white-text);
}

.question p {
  padding: 1rem 1.5rem;
  color: var(--grey-500);
  line-height: 1.5;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  text-align: unset;
}

.question-btn {
  font-size: 1.375rem;
  display: flex;
  padding: .15rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-primary-5-active);
  background-color: var(--clr-off-white-text);
  border-color: transparent;
  cursor: pointer;
  /* optional - last item */
  align-self: flex-start;
}

.question-btn svg {
  fill: var(--clr-secondary);
}

.question p .question-btn {
  display: inline;
  padding: 0;
  border: 0;
  font-size: 1rem;
  font-family: var(--ff-primary);
  font-weight: var(--fw-500);
  border-radius: unset;
}

@media screen and (min-width: 768px) {
  .questions-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
  }

  .question {
    width: calc(50% - 0.5rem);
    align-self: flex-start;
  }

  .question header {
    height: 5rem;
    align-items: start;
  }
}

@media screen and (min-width: 992px) {
  .section__about.section__faqs {
    padding-bottom: 7vw;
    padding-top: 0;
    min-height: unset;
    margin-bottom: 0;
  }

  .questions-wrapper {
    column-gap: 1.1111111111vw;
  }

  .question {
    width: calc(50% - 0.5555555555554vw);
    margin-bottom: 1.388888888vw;
  }

  .question header {
    height: clamp(50px, 5vw, 84px);
    align-items: center;
  }

  .question-btn {
    align-self: unset;
    font-size: clamp(15px, 1.5278vw, 25px);
    padding: 0.1666666666667vw;
  }

  .about-info.about-info__faqs p {
    font-size: clamp(12px, 1.075vw, 17px);
  }

  .question p .question-btn {
    font-size: 1.075vw;
  }
}

@media screen and (min-width: 1280px) {
  .section__about.section__faqs {
    padding-bottom: 10vh;
  }
}

@media screen and (min-width: 1440px) {
  .section__about.section__faqs {
    padding-bottom: 12vh;
  }
}

@media screen and (min-width: 1920px) {
  .section__about.section__faqs {
    padding-bottom: 10vh;
  }
}

/*
=============== 
Footer
===============
*/
.footer {
  background: var(--clr-primary-5);
  text-align: center;
  padding-left: .75rem;
  padding-right: .75rem;
}

.footer-links,
.footer-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.footer-icons {
  margin-top: 1.5rem;
}

.footer-links {
  grid-row-gap: 1rem;
  align-items: center;
}

.footer-links li:last-child {
  margin-top: 1px;
  margin-bottom: 1px;
}

.footer-links:last-child {
  flex-direction: column;
}

.footer-links:last-child .footer-link {
  font-size: 0.875rem;
  width: 12rem;
}

.footer-link {
  color: var(--clr-off-white-text);
  text-transform: capitalize;
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  letter-spacing: var(--spacing-slightly-reduced);
  transition: var(--transition);
  display: inline-block;
  width: 6rem;
}

.footer-link.footer-link__contact {
  background: none;
  border: none;
  font-family: var(--ff-primary);
}

.footer-icon {
  font-size: 1.7rem;
  color: var(--clr-white);
  transition: var(--transition);
  display: inline-block;
  min-width: 1.625rem;
  max-width: auto;
}

.footer-icons li:not(:last-child) {
  max-width: 4.2rem;
  margin-bottom: 1rem;
}

.footer-icons li:nth-last-child(2) .footer-icon,
.footer-icons li:last-child .footer-icon {
  margin-right: 0;
}

.copyright span {
  margin-left: 0.25rem;
}

.footer-nav-contact a {
  color: #28283d;
}

.footer-nav-contact .nav-item i {
  font-size: 1rem;
  margin-right: .5rem;
}

.footer-icon:last-child {
  max-width: 12rem;
}

@media screen and (min-width: 992px) {
  .footer {
    padding: 5vh 2.22222vw;
  }

  .footer-links,
  .footer-icons {
    margin-bottom: 3vh;
  }

  .footer-icons {
    margin-top: 3vh;
  }

  .footer-link {
    margin: 0 0.75vw;
    width: 8vw;
    font-size: clamp(12px, 1.125vw, 17px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-link:hover {
    color: var(--clr-secondary);
  }

  .footer-icon {
    font-size: clamp(20px, 2vw, 30px);
  }

  .footer-icon:hover {
    color: var(--clr-secondary);
  }

  .copyright {
    font-size: clamp(12px, 1.075vw, 17px);
    margin-bottom: 2rem;
  }

  .footer-links:last-child {
    margin-bottom: 0;
    flex-direction: row;
  }

  .footer-links:last-child .footer-link {
    font-size: clamp(12px, 1.075vw, 17px);
  }

  .footer-nav-contact {
    font-size: clamp(13px, 1.175vw, 18px);
  }

  .footer-nav-contact a:hover {
    font-weight: var(--fw-400);
    color: var(--clr-secondary);
  }

  .footer-icon:last-child {
    max-width: 11rem;
  }

  .footer-icons li:not(:last-child) {
    max-width: 3.9rem;
  }

  .footer-links:last-child .footer-link {
    width: clamp(8.125rem, 11vw, 10.5rem)
  }

  .footer-links:last-child li:first-child {
    order: 2;
  }

  .footer-links:last-child li:last-child {
    order: 3;
  }
}

@media screen and (min-width: 1280px) {
  .footer-icons {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .footer-icons li:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .footer-nav-contact .nav-item i {
    font-size: 1.25rem;
  }

  .footer-icon:last-child {
    max-width: 13rem;
  }

  .footer-icons li:not(:last-child) {
    max-width: 4.6rem;
    margin-bottom: 2rem;
  }
}

/*
=============== 
Navbar
===============
*/
/* add :not(.nav-logo) to img */

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 11;
  height: 3.75rem;
  display: flex;
  align-items: center;
  background: var(--clr-off-white);
}

.nav-icons {
  display: none;
}

.nav-center {
  width: 90vw;
  max-width: 1170px;
  margin: 0 auto;
}

.nav-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-toggle {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  cursor: pointer;
  transition: var(--transition);
}

.nav-toggle:hover {
  transform: scale(1.2);
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  text-transform: capitalize;
  padding: 1rem 1.5rem 1rem 33%;
  color: var(--grey-700);
  transition: var(--transition);
  letter-spacing: var(--spacing-reduced);
}

/* nav toggle functionality */
.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}

.show-links {
  height: 280px;
}

.nav-links>li>ul {
  display: none;
}

/* nav at bigger screen size */
@media screen and (min-width: 768px) {

  .nav-center {
    width: calc(100vw - 3rem);
  }
}

@media screen and (min-width: 992px) {

  /* hide nav toggle button */
  .nav-toggle {
    display: none;
  }

  /* show links */
  .nav-links {
    height: auto;
    display: flex;
  }

  .nav-logo {
    width: 8.26388888889vw;
  }

  .nav-center {
    display: flex;
    justify-content: center;
    align-items: center;
    /* optional */
    flex-wrap: wrap;
  }

  .nav-header {
    padding: 0 0;
  }

  .nav-link {
    padding: 0 0;
    font-size: clamp(14px, 1.25vw, 20px);
    margin-right: 1vw;
    margin-left: 1vw;
    width: clamp(90px, 9vw, 140px);
    border: none;
    background: none;
    font-family: var(--ff-primary);
    line-height: 2.25;
  }

  .nav-link:hover {
    padding: 0;
    color: var(--clr-primary-5);
    background: transparent;
  }

  .nav-icons {
    position: absolute;
    display: flex;
    width: 13.5vw;
    justify-content: space-between;
    right: 1.5rem;
  }

  .nav-icon {
    margin-right: 14px;
    color: var(--clr-primary-5);
    font-size: clamp(18px, 1.675vw, 1.675rem);
    transition: var(--transition);
    min-width: 1.5rem;
    display: inline-block;
  }

  .nav-icon:hover {
    color: var(--clr-secondary);
  }

  /* nav hoverable sub-menu styling - based on https://codepen.io/RayM/pen/ARQmVQ */


  .nav-links>li>ul {
    left: -99999rem;
    display: block;
  }

  .nav-links>li>ul>li>a {
    text-align: left;
    width: fit-content;
    margin-left: unset;
    margin-right: unset;
  }

  .nav-links li a:first-child:nth-last-child(2):before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border: 5px solid transparent;
    top: 50%;
    right: 5px;
  }

  .nav-links>li>a:first-child:nth-last-child(2):before {
    border-top-color: var(--clr-primary-5);
  }

  .nav-links>li>ul>li>a:hover {
    color: var(--clr-secondary-active);
  }

  .nav-links>li:nth-last-child(2):hover {
    background-color: #fafafa;
  }

  .nav-links>li:hover>ul {
    left: auto;
    min-width: 11vw;
    padding-left: 2.85vw;
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    padding-bottom: .5rem;
    box-shadow: var(--shadow-3);
  }
}

/* set hero to 
min-height:calc(100vh - 62px);
*/

/*
=============== 
Sidebar
===============
*/

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-off-white);
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: .75rem;
  box-shadow: var(--clr-red-dark);
  transition: var(--transition);
  padding-top: 3.75rem;
  /* toggle logic */
  transform: translateX(100%);
  z-index: 10;
}

.sidebar.show-sidebar {
  /* toggle logic */
  transform: translateX(0);
}

.logo {
  height: 40px;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.links a,
.links button {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  text-transform: capitalize;
  padding: 2vh 1.5rem 2vh 33%;
  color: var(--grey-700);
  transition: var(--transition);
  letter-spacing: var(--spacing-reduced);
}

.links button {
  border: none;
  background: none;
  font-family: var(--ff-primary);
  line-height: 1.5;
}

.links a svg,
.links button svg {
  font-size: 1.5rem;
  color: var(--clr-secondary-active);
  margin-right: 1rem;
  transition: var(--transition);
}

.links .submenu-link a {
  padding-left: calc(33% + 2.5rem);
}

.links .submenu-link a svg {
  color: var(--clr-primary-5);
}

.social-links {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.social-links.logo-text {
  height: 22vw;
  padding-bottom: unset;
  background-image: url(./images/perfect-match-logo_only-text.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.social-links a {
  font-size: 1.5rem;
  margin: 0 1rem;
  color: var(--clr-primary-5);
  transition: var(--transition);
}

@media screen and (min-width: 676px) {
  .sidebar {
    width: 400px;
  }

  .sidebar.show-sidebar {
    box-shadow: var(--shadow-3);
  }
}

@media screen and (min-width: 768px) {
  .sidebar {
    height: calc(100vh - 3.75rem);
    background-color: hsla(213, 24%, 15%, .6);
    width: 100vw;
    top: 3.75rem;
    padding: 0;
    row-gap: 0;
  }

  .social-links,
  .links {
    background-color: var(--clr-off-white-text);
  }

  .links {
    margin-top: -1px;
    margin-bottom: -2px;
  }

  .social-links a {
    font-size: 1.75rem;
    margin: 0 1rem;
  }

  .social-links {
    height: 23vh;
  }

  .social-links.logo-text {
    height: 27.5vh;
  }

  .social-links {
    clip-path: polygon(0% 0%, 100% 0, 100% 25%, 37% 100%, 0% 100%);
  }

  .social-links.logo-text {
    background-size: 36%;
    background-position-y: 66%;
    clip-path: polygon(0% 63%, 63% 0, 100% 0, 100% 100%, 0% 100%);
  }

  .links a,
  .links button {
    padding-left: 40%;
  }

  .links a svg {
    font-size: 1.75rem;
  }

  .links .submenu-link a {
    padding-left: calc(40% + 2.5rem);
  }
}

@media screen and (min-width: 992px) {
  .links a:hover {
    background: var(--grey-100);
    color: var(--grey-800);
  }

  .links a svg:hover {
    color: var(--clr-green-dark);
  }

  .social-links a:hover {
    color: var(--primary-800)
  }
}

/*
=============== 
Modal
===============
*/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}

.modal-overlay.show-modal {
  z-index: 10;
  visibility: visible;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 500ms;
}

.modal-container {
  background: var(--clr-white);
  width: 87.2vw;
  border-radius: var(--radius-increased);
  max-width: var(--width-fixed-540);
  text-align: center;
  display: grid;
  place-items: center;
  position: relative;
  top: 2rem;
  box-shadow: var(--shadow);
}

.modal-container.modal-container__application-form {
  height: 66vh;
  max-height: 39rem;
  top: 3.75rem;
}

.modal-container.modal-container__application-form.modal-container__cookie-consent {
  height: 37.5vh;
  max-height: 20rem;
}

.modal-container.modal-container__application-form.modal-container__contact-form {
  top: 1.875rem;
  height: unset;
  max-height: unset;
  grid-template-columns: unset;
  padding-left: unset;
}

.close-modal-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: transparent;
  border-color: transparent;
  cursor: pointer;
  z-index: 2;
}

.close-modal-btn.close-modal-btn__application-form {
  top: 2.5vh;
}

.close-modal-btn svg path {
  stroke: var(--clr-secondary-active);
}

@media screen and (min-width: 768px) {
  .modal-container.modal-container__application-form.modal-container__cookie-consent {
    max-height: 9rem;
  }

  .close-modal-btn {
    font-size: 2rem;
  }

  .close-modal-btn.close-modal-btn__application-form {
    top: 1rem;
  }

  .modal-container.modal-container__application-form.modal-container__contact-form {
    height: 66vh;
    max-height: 42rem;
  }

  .modal-container.modal-container__application-form {
    max-height: none;
  }
}

@media screen and (min-width: 992px) {
  .modal-container {
    max-width: unset;
    width: unset;
    box-shadow: unset;
  }

  .modal-container.modal-container__application-form {
    grid-template-columns: 20vw 1fr;
    padding-left: 1rem;
    height: 41.5vw;
    top: 1.875rem;
    max-height: unset;
  }

  .modal-container.modal-container__application-form.modal-container__stripe {
    grid-template-columns: unset;
  }

  .modal-overlay.show-modal {
    background: var(--clr-pale-blue-bg);
  }

  .modal-overlay.show-modal.modal-overlay__cookie-consent {
    background: rgba(0, 0, 0, 0.5);
  }

  .close-modal-btn {
    font-size: clamp(24px, 2.25vw, 38px);
  }

  .modal-container.modal-container__application-form.modal-container__contact-form {
    height: 70vh;
    min-height: 540px;
  }
}

@media screen and (min-width: 1280px) {
  .modal-container.modal-container__application-form {
    height: 43vw;
    max-height: 88vh;
  }
}

@media screen and (min-width: 1440px) {
  .modal-container.modal-container__application-form {
    max-width: 1120px;
  }

  .modal-container.modal-container__application-form {
    grid-template-columns: clamp(274px, 19vw, 320px) 1fr;
    max-height: 40rem;
  }
}

@media screen and (max-width: 320px) {
  .modal-container.modal-container__application-form.modal-container__cookie-consent {
    height: 54vh;
  }
}

/*
=============== 
Contact Form Modal
===============
*/

.form {
  width: 87.2vw;
  max-width: var(--width-fixed-540);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 3vh 1.5rem 0;
  margin: 3vh auto 1vh;
}

.form .fixed-height {
  height: 48vh;
  max-height: 24rem;
}

.form .fixed-height+div {
  margin-top: 1vh;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.775rem 0.75rem 0.775rem 1.125rem;
  border-radius: var(--radius-input);
  border: 1px solid var(--grey-300);
  font-size: 0.775rem;
  line-height: 1.86;
  font-family: var(--ff-primary);
  color: var(--grey-400);
  font-weight: var(--fw-600);
}

.form-input.form-input__select {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.form-input.form-input__date {
  padding-top: .7125rem;
  padding-bottom: .7125rem;
}

.form-input.label-q {
  padding-bottom: .5rem;
}

.form-input.label-q.label-q__availability {
  padding: 0 0 .5vh
}

.form-input.form-input__select>div {
  margin-right: 0;
}

.form-input::placeholder,
.form-textarea::placeholder {
  color: var(--clr-light-grey);
  font-weight: var(--fw-400);
}

.form-input:focus-visible,
.form-textarea:focus-visible {
  outline-color: var(--clr-primary-5);
}

.form-row {
  margin-bottom: .75rem;
  position: relative;
}

.form-row.form-row__requirements {
  margin-bottom: 4vh;
}

.form-row.form-row__requirements:nth-child(3) {
  margin-top: 2vh;
}

.form-row.form-row__additional-requirements {
  margin-bottom: 1rem;
}

.form-row.form-row__additional-requirements__last {
  margin-bottom: .25rem;
}

.form-row.form-row__additional-requirements.form-row.form-row__stripe {
  margin-bottom: 10rem;
}

.form-row.form-row__additional-requirements:first-of-type {
  margin-top: 1.25rem;
}

.agreement-text__alt+.form-row.form-row__additional-requirements.form-row__agreement {
  margin-top: 1rem;
  margin-bottom: 0;
}

.form-row.form-row__additional-requirements.form-row.form-row__almost-there {
  margin-bottom: 2rem;
}

.form-textarea {
  height: 7rem;
}

::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}

.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}

/* extra styles */
.form .form-label {
  position: absolute;
  top: calc(50% - 15px);
  left: 1.213125rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.725rem;
  background: var(--clr-secondary);
}

.form .btn-block {
  margin-top: 0.5rem;
}

.modal-footer {
  margin-left: 11.9266%;
  margin-right: 11.9266%;
  padding-bottom: 4px;
}

.modal-footer-text {
  color: var(--clr-grey-muted);
  font-size: 12px;
  line-height: 1.5;
  font-weight: var(--fw-500);
  margin-bottom: calc(4vh + 1.25rem);
}

.modal-footer-text span {
  font-weight: var(--fw-700);
  color: var(--clr-secondary);
}

.modal-footer-text.modal-footer-text__application-form br {
  display: none;
}

.modal-footer-text.modal-footer-text__application-form span {
  font-weight: var(--fw-600);
  color: var(--grey-400);
}

.form.form__cookies {
  margin-bottom: 3vh;
  margin-top: 1vh;
}

.para__learn-more {
  margin-top: 1.5rem;
  font-size: 1rem;
}

.link__learn-more {
  display: none;
}

.form-row.form-row__additional-requirements.form-row__agreement svg {
  width: 4.25rem;
  height: 4.25rem;
  margin-bottom: 0.75rem;
}

.form-row.form-row__additional-requirements.form-row__agreement .icon-stripe {
  width: 4.5rem;
  height: auto;
  margin-bottom: 0;
}

.form-row.form-row__additional-requirements.form-row__agreement .icon-pram {
  width: 5rem;
  height: 5rem;
}

.form-row.form-row__additional-requirements.form-row__agreement .icon-family,
.form-row.form-row__additional-requirements.form-row__agreement .icon-nanny {
  width: 2.5rem;
  height: 2.5rem;
}

.form-row.form-row__additional-requirements.form-row__agreement h5 {
  font-size: 1.5rem;
  margin-bottom: 0.375rem;
}

.form-row.form-row__additional-requirements.form-row__agreement .agreement-text {
  color: var(--clr-grey);
  font-size: .95rem;
  margin-bottom: 0.375rem;
  text-align: center;
}

.form-row.form-row__additional-requirements.form-row__agreement .agreement-text:last-of-type {
  margin-bottom: 1.5rem;
}

.form-row.form-row__additional-requirements.form-row__agreement .agreement-text__alt {
  color: var(--grey-700);
  font-size: .875rem;
  margin-bottom: 0.5rem;
  text-align: left;
  font-style: oblique;
  font-weight: var(--fw-600);
}

.form-row.form-row__additional-requirements.form-row__agreement .agreement-text__alt:last-of-type {
  margin-bottom: 1rem;
}

.form-row.form-row__driving-licence {
  margin-bottom: 3.586875rem;
}

.form-row.form-row__nationality {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.form-row.form-row__permit {
  margin-bottom: 3rem;
}

.form-row.form-row__experience {
  margin-bottom: 4vh;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: .25rem;
  top: calc(50% - 16px);
}

.react-datepicker__view-calendar-icon input.form-input__date {
  padding: .7125rem .75rem .7125rem 1.125rem;
  line-height: 2;
}

.custom-date-cell {
  height: 3vh;
}

.custom-time-label {
  font-size: clamp(11px, 2.66666666667vw, 14px);
}

.custom-date-label {
  font-size: clamp(12px, 3vw, 15px);
}

.form .fixed-height+div {
  gap: .125rem;
}

.form.form__contact {
  margin-top: 1vh;
  margin-bottom: 3vh;
}

.para__cookies {
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .form {
    padding-left: 5rem;
    padding-right: 5rem;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 1vh;
  }

  .form-row {
    margin-bottom: 1.25rem;
  }

  .form-row.form-row__children {
    margin-bottom: 5vh;
  }

  .modal-footer-text {
    margin-bottom: 2.25rem;
  }

  .form-input,
  .form-textarea {
    font-size: 0.95rem;
    padding: 0.875rem 1.5rem;
  }

  .form-input.form-input__select {
    padding-top: 1.0625rem;
    padding-bottom: 1.0625rem;
  }

  .form .fixed-height {
    max-height: 30rem;
  }

  .form-input.label-q.label-q__availability {
    padding-bottom: 1vh;
  }

  .form-row.form-row__requirements {
    margin-bottom: 2.5rem;
  }

  .form-row.form-row__additional-requirements {
    margin-bottom: 1.75rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement svg {
    width: 5.5rem;
    height: 5.5rem;
    margin-bottom: 0;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .icon-stripe {
    width: 5rem;
    margin-bottom: 0;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .icon-pram {
    width: 5.5rem;
    height: 5.5rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .icon-family,
  .form-row.form-row__additional-requirements.form-row__agreement .icon-nanny {
    width: 3.25rem;
    height: 3.25rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement h5 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .agreement-text {
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  .form-row.form-row__additional-requirements.form-row__agreement:nth-child(5) {
    margin-bottom: -1rem;
  }

  .form-row.form-row__additional-requirements p {
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .form-input.form-input__date {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .form.form__cookies {
    max-width: unset;
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .form-row__cookies,
  .para__cookies,
  .para__learn-more {
    margin-bottom: 0;
  }

  .para__cookies {
    text-align: left;
  }

  .para__learn-more {
    display: none;
  }

  .link__learn-more {
    display: inline;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .agreement-text__alt {
    font-size: 1.125rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .agreement-text__alt:last-of-type {
    margin-bottom: 0.75vh;
  }

  .form-row.form-row__nationality {
    margin-bottom: 5.16vh;
  }

  .modal-footer-text {
    font-size: .85rem;
  }

  .modal-footer-text.modal-footer-text__application-form {
    margin-bottom: 6vh;
  }

  .modal-footer-text.modal-footer-text__application-form br {
    display: inline;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .agreement-text:last-of-type {
    margin-bottom: 0;
  }

  .form-row.form-row__additional-requirements.form-row.form-row__stripe {
    margin-bottom: 25vh;
  }

  .react-datepicker__view-calendar-icon input.form-input__date {
    padding: .8125rem 1.5rem;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 1.125rem;
  }
}

@media screen and (min-width: 992px) {
  .form {
    padding-left: 7vw;
    padding-right: 7vw;
    width: 45vw;
    max-width: 780px;
    min-height: calc(39vw - 2rem);
    margin-top: 0;
    margin-bottom: 0;
    align-self: end;
    padding-top: 0;
  }

  .form.form__contact {
    padding-left: 4vw;
    padding-right: 4vw;
    width: 38vw;
    max-width: unset;
    min-height: unset;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .form.form__cookies {
    padding-left: 0;
    padding-right: 0;
    width: 71vw;
    height: 9rem;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 1080px;
  }

  .form-input,
  .form-textarea {
    font-size: clamp(0.785rem, 1.1vw, 1.143rem);
    padding-top: clamp(9px, 0.7375vw, 12px);
    padding-bottom: clamp(9px, 0.7375vw, 12px);
    padding-left: 1.675vw;
    padding-right: 1.675vw;
    border-radius: var(--radius);
    line-height: 1.575;
  }

  .form-row {
    margin-bottom: 1.389vw;
  }

  .form-row.form-row__contact {
    margin-bottom: 2.2vh;
  }

  .form-input.form-input__select {
    padding-top: clamp(.6rem, 1.3vh, 14px);
    padding-bottom: clamp(.6rem, 1.3vh, 14px);
  }

  .form-row.form-row__requirements {
    margin-bottom: 4.444vh;
  }

  .form-row.form-row__additional-requirements p {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }

  .form-row.form-row__additional-requirements:first-of-type {
    margin-top: 0;
  }

  .form-row.form-row__additional-requirements.form-row__agreement svg {
    width: clamp(3.25rem, 5vw, 80px);
    height: clamp(3.25rem, 5vw, 80px);
    margin-bottom: 0.5vh;
  }

  .form-input.form-input__date,
  .react-datepicker__view-calendar-icon input.form-input__date {
    padding-top: clamp(8px, .66666vw, 11px);
    padding-bottom: clamp(8px, .66666vw, 11px);
  }

  .react-datepicker__view-calendar-icon input.form-input__date {
    line-height: 1.75;
    padding-left: 1.675vw;
    padding-right: 1.675vw;
  }

  .form-row__cookies {
    margin-bottom: 0;
    margin-right: 80px;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .agreement-text:last-of-type {
    margin-bottom: 1vh;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .icon-pram {
    width: clamp(4rem, 7vw, 6.25rem);
    height: clamp(4rem, 7vw, 6.25rem);
  }

  .form-row.form-row__additional-requirements.form-row__agreement .icon-family,
  .form-row.form-row__additional-requirements.form-row__agreement .icon-nanny {
    width: clamp(3rem, 5.78vh, 4rem);
    height: clamp(3rem, 5.78vh, 4rem);
  }

  .form-row.form-row__additional-requirements.form-row__agreement h5 {
    font-size: clamp(25px, 2.375vw, 42px);
    margin-bottom: 0;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .agreement-text {
    font-size: clamp(14px, 1.175vw, 18px);
    margin-bottom: 0;
  }

  .form-row.form-row__additional-requirements.form-row__agreement {
    gap: 1.777778vh;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .agreement-text__alt {
    font-size: clamp(13px, 1.125vw, 20px);
    margin-bottom: 0;
  }

  .form-row.form-row__additional-requirements {
    margin-bottom: 3.111111vh;
  }

  .form-row.form-row__additional-requirements.form-row__agreement .icon-stripe {
    width: clamp(60px, 5.5vw, 100px);
  }

  .form-row.form-row__experience {
    margin-bottom: 5.75vh;
  }

  .form-row.form-row__driving-licence,
  .form-row.form-row__permit {
    margin-bottom: 7.74vh;
  }

  .modal-footer-text {
    font-size: clamp(0.7rem, 1vw, 1rem);
  }

  .form .fixed-height {
    max-height: 100%;
    height: calc(30.5vw - 2rem + 1.4vh);
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: calc(1.675vw - 8px);
  }

  .custom-date-cell {
    height: 2.8vh;
  }

  .custom-time-label {
    font-size: .7125rem;
  }

  .custom-date-label {
    font-size: .75rem;
  }

  .form .fixed-height+div {
    gap: .5rem;
  }

  .form .fixed-height+div,
  .form .container__btn.move-btn-down {
    margin-top: 2vh;
  }

  .form-row.form-row__additional-requirements.form-row.form-row__almost-there {
    margin-bottom: 0;
  }

  .agreement-text__alt+.form-row.form-row__additional-requirements.form-row__agreement {
    margin-top: 0;
  }

  .form-row.form-row__additional-requirements.form-row.form-row__stripe {
    margin-bottom: 8vh;
  }

  .para__cookies {
    font-size: clamp(14px, 1.25vw, 20px);
  }
}

@media screen and (min-width: 1280px) {
  .custom-date-cell {
    min-height: 23px;
  }

  .custom-time-label {
    font-size: .8125rem;
  }

  .custom-date-label {
    font-size: .875rem;
  }

  .form-row {
    margin-bottom: 1.5vw;
  }

  .form-row__cookies {
    margin-bottom: 0;
  }

  .form-row.form-row__core-requirements__last {
    margin-bottom: 6vh;
  }

  .form-row.form-row__additional-requirements__last {
    margin-bottom: 3vh;
  }

  .form-row.form-row__additional-requirements.form-row.form-row__stripe {
    margin-bottom: 10vh;
  }

  .form-input.label-q.label-q__availability {
    padding-top: 2vh;
  }
}

@media screen and (min-width: 1440px) {
  .custom-date-cell {
    height: 3vh;
  }

  .custom-time-label {
    font-size: 14px;
  }

  .custom-date-label {
    font-size: 15px;
  }

  .form {
    height: clamp(530px, calc(39vw - 2rem), 34rem);
    min-height: unset;
  }

  .form .fixed-height {
    max-height: 440px;
  }

  .form-input.label-q.label-q__availability {
    padding-top: 1vh;
  }
}

/*
=============== 
Application Form Modals
===============
*/

.progress-bar {
  position: absolute;
  overflow: hidden;
  counter-reset: step;
  top: -22vw;
  width: 90vw;
  display: flex;
  justify-content: space-between;
  padding-top: 7.2533333vw;
}

.progress-bar li {
  list-style-type: none;
  color: transparent;
  text-transform: uppercase;
  font-size: .75rem;
  width: 10%;
  float: left;
  position: relative;
}

.progress-bar li.active::before,
.progress-bar li.active::after {
  background: var(--clr-primary-5-active);
  color: var(--clr-off-white-text);
}

.progress-bar li::before {
  content: counter(step);
  counter-increment: step;
  width: 7vw;
  line-height: 7vw;
  display: block;
  font-size: 3.75vw;
  color: var(--clr-off-white-text);
  background: var(--clr-secondary-active);
  border-radius: 50%;
  margin: 0 auto .375rem;
}

.progress-bar li::after {
  content: "";
  width: 100%;
  height: .125rem;
  background: var(--clr-secondary-active);
  position: absolute;
  left: -50%;
  top: .7875rem;
  z-index: -1;
}

.progress-bar li:first-child::after {
  content: none;
}

.form-heading {
  color: var(--clr-grey);
  letter-spacing: var(--spacing-slightly-reduced);
  font-size: 1.125rem;
  top: 2.4vh;
  left: 50%;
  transform: translateX(-50%);
  width: 67vw;
}

.bg-cv {
  height: 17.5vh;
  width: 17.5vh;
}

.children-container {
  margin-bottom: 2vh;
}

@media screen and (min-width: 768px) {
  .progress-bar {
    width: 95vw;
  }

  .progress-bar__parents {
    width: 96vw;
  }

  .progress-bar li {
    color: var(--clr-off-white-text);
    font-weight: var(--fw-600);
    width: 12%;
    font-size: .775rem;
  }

  .progress-bar__parents li {
    font-size: .7125rem;
  }

  .progress-bar li::before {
    width: 2.25rem;
    line-height: 2.25rem;
    font-size: 1.05rem;
  }

  .progress-bar li::after {
    top: 1rem;
  }

  .form-heading {
    margin-bottom: 0;
    font-size: 1.5rem;
  }

  .bg-cv {
    height: 15vh;
    width: 15vh;
  }

  .children-container {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 992px) {
  .progress-bar {
    width: 100%;
    flex-direction: column;
    position: unset;
    background-image: url(./images/bg-progress-bar-desktop.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100% - 2rem);
    border-radius: var(--radius-increased);
    justify-content: flex-start;
    padding-top: 1rem;
    padding-left: 1rem;
    background-position: bottom;
    max-width: 20rem;
  }

  .progress-bar li {
    width: unset;
    float: unset;
    text-align: left;
    font-size: clamp(.725rem, .9vw, 1rem);
    max-height: 54px;
  }

  .progress-bar li::before {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    margin-left: 0;
    margin-bottom: 1.25vw;
    font-size: 1.05vw;
    width: clamp(24px, 1.85vw, 37px);
    line-height: clamp(24px, 1.85vw, 37px);
    background: transparent;
    border: 1px solid var(--clr-white);
  }

  .progress-bar li.active::before {
    background: var(--clr-secondary-active);
    border-color: var(--clr-secondary-active);
  }

  .form-heading {
    width: 45vw;
    left: unset;
    right: clamp(224px, 22.5vw, 390px);
    transform: translateX(50%);
    font-size: clamp(18px, 1.666666667vw, 27px);
    max-width: 780px;
  }

  .bg-cv {
    height: 17.5vh;
    width: 17.5vh;
  }
}

@media screen and (min-width: 1280px) {
  .form-row.form-row__children {
    margin-bottom: 10vh;
  }

  .progress-bar li {
    font-size: clamp(.75rem, .95vw, 15px);
  }

  .progress-bar li::before {
    font-size: 1.125vw;
    width: clamp(24px, 2.25vw, 37px);
    line-height: clamp(24px, 2.25vw, 37px);
  }
}

@media screen and (min-width: 1440px) {
  .progress-bar {
    justify-self: start;
  }

  .progress-bar li::before {
    font-size: clamp(1.0125rem, 1.125vw, 20px);
  }
}

/* custom radio buttons - based on https://www.w3schools.com/howto/howto_css_switch.asp */

.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--clr-bg-grey);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 3.5px;
  bottom: 3.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.img-wrapper {
  height: 10vh;
  width: 10vh;
  margin-bottom: 2vh;
}

.slider-wrapper>span {
  color: var(--clr-grey);
  font-weight: var(--fw-500);
  letter-spacing: var(--spacing-reduced);
  width: 2.25rem;
}

.slider-wrapper>.core-requirements {
  width: 6.125rem;
}

.slider-wrapper>.slider-label-additional {
  width: 7rem;
}

.slider-wrapper>.slider-label-additional.slider-label-confirm {
  width: 4.5rem;
}

input:checked+.slider {
  background-color: var(--clr-primary-5);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--clr-secondary);
}

input:checked+.slider::before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .switch {
    width: 4rem;
    height: 2rem;
  }

  .slider::before {
    height: 25px;
    width: 25px;
  }

  input:checked+.slider::before {
    -webkit-transform: translateX(2rem);
    -ms-transform: translateX(2rem);
    transform: translateX(2rem);
  }

  .img-wrapper {
    height: 12vh;
    width: 12vh;
    margin-bottom: .625rem;
  }

  .slider-wrapper>.core-requirements {
    width: 6.875rem;
  }

  .slider-wrapper>.slider-label-additional {
    width: 7.625rem;
  }

  .slider-wrapper>.slider-label-additional.slider-label-confirm {
    width: 5rem;
  }
}

@media screen and (min-width: 992px) {
  .slider-wrapper>span {
    font-size: clamp(0.785rem, 1.1vw, 18px);
  }

  .switch {
    width: clamp(46px, 4.444vw, 72px);
    height: clamp(23px, 2.222vw, 36px);
  }

  .slider::before {
    height: clamp(18px, 1.736vw, 28px);
    width: clamp(18px, 1.736vw, 28px);
    left: clamp(.1625rem, 0.275vw, 4px);
    bottom: clamp(.1625rem, 0.25vw, 4px);
  }

  input:checked+.slider::before {
    -webkit-transform: translateX(clamp(22px, 2.2vw, 36px));
    -ms-transform: translateX(clamp(22px, 2.2vw, 36px));
    transform: translateX(clamp(22px, 2.2vw, 36px));
  }

  .img-wrapper {
    margin-bottom: 1vh;
  }
}

@media screen and (min-width: 1440px) {
  .slider-wrapper>.core-requirements {
    width: 7.5rem;
  }

  .slider-wrapper>.slider-label-additional {
    width: 8.625rem;
  }
}

/*
=============== 
Parents or Nanny Toggle Modal
===============
*/

.form-row.form-row__additional-requirements.form-row__agreement.form-row__parents-or-nanny h5 {
  font-size: 1.5rem;
  margin-bottom: .375rem;
}

.form-row.form-row__additional-requirements.form-row__agreement.form-row__parents-or-nanny p {
  font-size: .95rem;
  margin-bottom: .375rem;
}

@media screen and (min-width: 768px) {
  .form-row.form-row__additional-requirements.form-row__agreement.form-row__parents-or-nanny {
    gap: .75rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement.form-row__parents-or-nanny h5 {
    font-size: 2rem;
    margin-bottom: .5rem;
  }

  .form-row.form-row__additional-requirements.form-row__agreement.form-row__parents-or-nanny p {
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
  }
}

@media screen and (min-width: 992px) {
  .form-row.form-row__additional-requirements.form-row__agreement.form-row__parents-or-nanny h5 {
    font-size: clamp(25px, 2.375vw, 42px);
  }

  .form-row.form-row__additional-requirements.form-row__agreement.form-row__parents-or-nanny p {
    margin-bottom: 0;
    font-size: clamp(14px, 1.25vw, 20px);
  }
}

/*
=============== 
React-Toastify custom styles
===============
*/

.toast-position {
  top: 3.75rem;
}

.toast-position .Toastify__progress-bar--warning {
  background: var(--clr-primary-5);
}

.Toastify__toast.toast-position {
  svg {
    fill: var(--clr-primary-5);
  }

  font-family: var(--ff-primary);
}

.Toastify .Toastify__toast-container--top-left .toast-position .Toastify__toast-body {
  justify-content: center;
}

.Toastify .Toastify__toast-container--top-left .toast-position .Toastify__toast-body>div:last-child {
  flex: unset;
}

@media screen and (min-width: 768px) {
  .Toastify .Toastify__toast-container--top-left {
    top: 3.75rem;
    left: 0;
    padding: 0;
    width: 100vw;
  }

  .Toastify .Toastify__toast-container--top-left .toast-position {
    top: unset;
    border-radius: 0;
  }

  .Toastify .Toastify__toast-container--top-left .toast-position .Toastify__progress-bar--wrp,
  .Toastify .Toastify__toast-container--top-left .toast-position .Toastify__progress-bar--wrp .Toastify__progress-bar {
    border-bottom-left-radius: 0;
  }
}

@media screen and (min-width: 992px) {

  .Toastify .Toastify__toast-container--top-left,
  .Toastify .Toastify__toast-container--top-left .toast-position {
    top: 0;
  }

  .Toastify .Toastify__toast-container--top-left .toast-position {
    font-size: clamp(0.785rem, 1.1vw, 1.143rem);
    min-height: 3.75rem;
    width: calc(65vw + 1rem);
    left: calc(17.5vw - .5rem);
    border-radius: var(--radius-input);
  }

  .Toastify .Toastify__toast-container--top-left .toast-position .Toastify__toast-body {
    padding: 0;
  }
}

@media screen and (min-width: 1440px) {
  .Toastify .Toastify__toast-container--top-left .toast-position {
    width: clamp(938px, calc(64vw + 1rem), 1120px);
    left: calc(50% - (clamp(938px, calc(64vw + 1rem), 1120px) / 2));
  }
}